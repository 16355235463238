import axios from 'axios'
import React from 'react'
import {Dropdown} from 'react-bootstrap'

function Inputs({dates, setDates, setDataTableData, accreditationToggle, setAccreditationToggle, baseUrl, setAgg, aggURL}) {

    const formatDate = (date) => {
        let output = [date.slice(4, 6), "/", date.slice(6), "/", date.slice(0,4)].join('')
        return output
    }

    const handleSubmission = (e) =>{
        e.preventDefault()
        if(accreditationToggle) baseUrl += '&accred=true' 
        axios.get(baseUrl + aggURL).then(res => {
            setDataTableData(res.data.data)
        })

    }
    const handleDateChange = (e) => {
        setDates({...dates, [e.target.id]:String(e.target.value).replaceAll('-','')})
    }

    const handleAccreditationToggle = () => {
        setAccreditationToggle(!accreditationToggle)
    }
    console.log(dates)
    return (
        <div className="inputs">
            <form onSubmit={handleSubmission}>
                <div className="input">
                    <label>MRC Accredited</label><input onChange={handleAccreditationToggle} type="checkbox"></input>
                </div>
                <div className="input">
                    <label>Date: {formatDate(dates.startDate)} - {formatDate(dates.endDate)}</label>
                </div>
                <div className="input">
                    <label>Start Date</label> <input name='start_date' onChange={handleDateChange} id='startDate' type='date'></input>
                    <label> End Date</label> <input name='end_date' onChange={handleDateChange} id='endDate' type='date'></input>
                </div>
                <div className="buttons">    
                <Dropdown className="dropdown">
                    <Dropdown.Toggle>
                        Aggregation Level
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setAgg('os')}>OS</Dropdown.Item>
                        <Dropdown.Item onClick={() => setAgg('browser')}>Browser</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <button className="submit" type="submit">Apply</button> 
                </div>            
            </form>
        </div>
    )
}

export default Inputs
