import { Table, Container } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';

import React from 'react'

function DataTable({ dataTableData: { summary, details }, agg }) {

    const [dataAvailable, setdataAvailable] = useState(false)

    useEffect(() => {
        
        
    }, [])

    useEffect(() => {
        console.log(summary)
        console.log(details)
        if (Array.isArray(summary) && summary.length) {
            setdataAvailable(true)
        }
        else {
            setdataAvailable(false)
        }
    }, [summary])


    const accreditationMapping = (datasource) => {
        const mapping = {
            A: 'Accredited',
            B: 'Unaccredited'
        }
        return mapping[datasource]
    }

    if (dataAvailable) {
        return (
            <Container>
                <Table responsive variant="dark" className="data-table">
                    <thead>
                        <tr>
                            <th>{String(agg).toUpperCase()}</th>
                            <th>Impressions Analyzed (unfiltered)</th>
                            <th>Impressions Analyzed (filtered for GIVT)</th>
                            <th>Impressions Analyzed</th>
                            <th>Measured Impressions</th>
                            <th>Measured Rate</th>
                            <th>Viewable Impressions</th>
                            <th>Viewable Rate</th>
                        </tr>
                    </thead>
                    <tbody>

                        {summary.map((summary, idx) => {
                            return (
                                <tr key={idx} className="summary-row">

                                    <td>Summary</td>
                                    <td>{summary.uimp}</td>
                                    <td>{summary.gimp}</td>
                                    <td>{summary.simp}</td>
                                    <td>{summary.vmeas}</td>
                                    <td>{((summary.vmeas) / (summary.simp) * 100).toFixed(2)}%</td>
                                    <td>{summary.vis}</td>
                                    <td>{((summary.vis) / (summary.simp) * 100).toFixed(2)}%</td>
                                </tr>
                            )
                        })}

                        {details.map((detail, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{detail[agg]}</td>
                                    <td>{detail.uimp}</td>
                                    <td>{detail.gimp}</td>
                                    <td>{detail.simp}</td>
                                    <td>{detail.vmeas}</td>
                                    <td>{((detail.vmeas) / (detail.simp) * 100).toFixed(2)}%</td>
                                    <td>{detail.vis}</td>
                                    <td>{((detail.vis) / (detail.simp) * 100).toFixed(2)}%</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
            </Container>
        )
    }
    else {
        return (
            <Container className="errorContainer">
                <h2>No data available for this date range.</h2>
            </Container>
        )
    }


}

export default DataTable
