import Inputs from './Inputs';
import DataTable from './DataTable'
import { useState, useEffect } from 'react';
import axios from 'axios'
import moment from 'moment'

function Dashboard({ title, endpoint}) {
  console.log(moment().format('YYYYMMDD'))

  const [dates, setDates] = useState({
    startDate: moment().format('YYYYMMDD'),
    endDate: moment().format('YYYYMMDD')
  })
  const [dataTableData, setDataTableData] = useState({})
  const [accreditationToggle, setAccreditationToggle] = useState(false)
  const [agg, setAgg] = useState('os')
  let baseURL = `/stats/${endpoint}?sdate=${dates.startDate}&edate=${dates.endDate}`
  console.log(baseURL)
  console.log(agg)
  
  let aggURL = `&agg=${agg}`

  useEffect(() => {
    axios.get(baseURL + aggURL).then((res) => {
      setDataTableData(res.data.data)
    })

  }, [])

  useEffect(() => {
    aggURL = `&agg=${agg}`
    if (accreditationToggle){
      axios.get(baseURL + aggURL + "&accred=true").then((res) => {
        setDataTableData(res.data.data)
      })
    }
    else{
      axios.get(baseURL + aggURL).then((res) => {
        setDataTableData(res.data.data)
      })
    }

  }, [agg])

  return (
    <div className="App">
      <h1>{title} Analytics Dashboard</h1>
      <Inputs dates={dates} setDates={setDates} aggURL={aggURL} setDataTableData={setDataTableData} accreditationToggle={accreditationToggle} setAccreditationToggle={setAccreditationToggle} baseUrl={baseURL} setAgg={setAgg}/>
      <DataTable dataTableData={dataTableData} agg={agg}/>
    </div>
  );
}

export default Dashboard;
