import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css'
import Dashboard from './components/Dashboard';
import TopNav from './components/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



function App() {
  

  return (
    <Router>
      <TopNav />
      <Switch>
        <Route exact path="/">
          <Dashboard title="MMMGDD" endpoint="test" agg="datasource"/>
        </Route>
        <Route exact path="/test-dash/">
          <Dashboard title="Test" endpoint="0" agg="os"/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
