import React from 'react'
import { Navbar, Container, Nav} from 'react-bootstrap'

function TopNav() {
    return (
        <>
            <Navbar bg="dark" variant="dark" className="nav-bar">
                <Container>
                    <Navbar.Brand>Analytics Dashboard</Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/testpages/index.html">Test Page</Nav.Link>
                        <Nav.Link href="/test-dash">Test Page Dashboard</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
           
        </>
    )
}

export default TopNav
